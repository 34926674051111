import React from "react";
import "./App.css";

function App() {
  return (
    <div>
      <div className="gpslandingpage">
        <div className="row">
          <img
            alt="amcrestimage"
            src="./AmcrestLogo.jpg"
            className="gpslandingpage_image"
          />
        </div>
      </div>
      <hr className="noMargin" />
      <div className="trackercontainer">
        <div className="container">
          <div className="text-center">
            <div className="trackercontainerinside">
              <h2 className="font-weight-thin text-center mb-3">
                Log in to Your Accounts
              </h2>
              <h5 className="font-weight-thin text-center text-secondary">
                Select from our GPS Tracker or GPS Fleet services.
              </h5>
              <hr className="hr-noline" />
              <hr className="hr-noline" />
              <div className="row text-center justify-content-center">
                <div className="col-lg-5">
                  <a
                    className="services tracker"
                    href="https://amcrestgps.net/#/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <div className="imagecontainer">
                      <img
                        src="./AmcrestImage.jpg"
                        alt="GPS Tracker Login Icon"
                        className="icon"
                      />
                      <h5 className="imagearrow">→</h5>
                      <img
                        src="ProAppImage.webp"
                        alt="GPS appicon"
                        width={116}
                        style={{ borderRadius: "25px" }}
                      />
                    </div>
                    <h5>GPS Tracker Login →</h5>
                  </a>
                </div>
                <div className="col-lg-5">
                  <a
                    className="services fleet"
                    href="https://amcrestgpsfleet.com/#/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <div className="imagecontainer">
                      <img
                        src="./FleetImage.png"
                        alt="GPS Fleet Login Icon"
                        className="icon"
                      />
                      <h5 className="imagearrow">→</h5>
                      <img
                        src="FleetAppImage.webp"
                        alt="GPS appicon"
                        width={116}
                        style={{ borderRadius: "25px" }}
                      />
                    </div>
                    <h5>GPS Fleet Login →</h5>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="login-footer"
        style={{
          background: "url(./login-footer-bg.jpg)",
        }}
      >
        <div className="container text-center">
          <div className="row">
            <div className="col-md-6 ">
              <img
                src="ProAppImage.webp"
                alt="GPS appicon"
                width={116}
                style={{ borderRadius: "25px" }}
              />
              <hr className="hr-noline" />
              <h3>Download the Amcrest GPS Pro app</h3>
              <hr className="hr-noline" />
              <a
                href="https://apps.apple.com/in/app/amcrest-gps-pro/id1463405292"
                target="_blank"
                rel="noreferrer"
              >
                <img alt="iOS" src="./prefooter-appstore-icon.svg" />
              </a>
              <a
                href="https://play.google.com/store/apps/details?id=com.obd"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  className="imagemobileresponsive"
                  alt="Android"
                  src="./prefooter-googleplay-icon.svg"
                />
              </a>
            </div>
            <div className="col-md-6 mobilemargintop">
              <img
                src="FleetAppImage.webp"
                alt="GPS appicon"
                width={116}
                style={{ borderRadius: "25px" }}
              />
              <hr className="hr-noline" />
              <h3>Download the Amcrest GPS Fleet app</h3>
              <hr className="hr-noline" />
              <a
                href="https://apps.apple.com/nz/app/amcrest-gps-fleet/id1493902109"
                target="_blank"
                rel="noreferrer"
              >
                <img alt="iOS" src="./prefooter-appstore-icon.svg" />
              </a>
              <a
                href="https://play.google.com/store/apps/details?id=com.piyush.obd&hl=en&gl=US"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  className="imagemobileresponsive"
                  alt="Android"
                  src="./prefooter-googleplay-icon.svg"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
